import userStateManager from "../helpers/stateManager";

export const component = async () => {
  const module = await import("focus-trap");
  const createFocusTrap = module.default;

  const slick = await import("../vendor/jquery.slick");

  var $onboarding = $(".onboarding");
  var $skip = $("#onboarding-skip");
  var $slider = $(".onboarding__slides");
  var $next = $(".tooltip__next");
  var totalSlides = 0;
  var $currentPageCurrent = $("#onboarding-counter-current");
  var $currentPageTotal = $("#onboarding-counter-total");
  var skipLimit = 5;
  var focusTrap;
  var currentTourState = {};
  var isDesktopView = $(window).width() > 1200;
  var tourRanThisSession = sessionStorage.getItem("tourRanThisSession");
  var previousText = 'Prev<span class="sr-only">ious Slide</span>';
  var nextText = 'Next<span class="sr-only"> Slide</span>';

  //Allow users to reset their tour
  window.resetTour = function () {
    console.log("resetting tour");
    //Update desktop or mobile tour
    var tourResetData = {
      TourCompleted: false,
      TourSkipCount: 0,
      TourStep: 0,
    };
    userStateManager.setSubState(tourResetData, "TourState", function () {
      userStateManager.setSubState(tourResetData, "MobileTourState");
    });
    //Update session status
    sessionStorage.removeItem("tourRanThisSession");
  };

  if ($onboarding.length < 1 || tourRanThisSession === "true") {
    return false;
  }

  userStateManager.getUserState(function (data) {
    // console.log('Initial user state:');
    // console.log(data);

    if (currentTourState !== null && currentTourState !== undefined) {
      //Set tour state based on device type and decide if we progress
      if (isDesktopView === true) {
        currentTourState = data.TourState;
        if (currentTourState.TourCompleted === false) {
          //Timeout to give fonts a chance to load
          setTimeout(() => {
            startTour();
          }, 2000);
        }
      } else if (data && data.MobileTourState) {
        currentTourState = data.MobileTourState;
        if (currentTourState.TourCompleted === false) {
          setTimeout(() => {
            startTour();
          }, 2000);
        }
      }
    }
  });

  function startTour() {
    $("html").addClass("hide-scroll");

    //Remove the slide that's not on mobile
    if (!isDesktopView) {
      $(".slide--removed-mobile").remove();
    }
    //Set total slides
    totalSlides = $slider.children().length;

    $slider.on("init", function (ev, init) {
      $slider.find(".slick-dots").prependTo(".onboarding__slides");
      $skip.insertBefore($slider.find(".slick-next"));
      $onboarding.fadeIn();
      $onboarding.find("a, button").first().focus();

      $currentPageTotal.text(totalSlides);

      setupEvents();

      focusTrap = createFocusTrap("#onboarding");
      focusTrap.activate();

      //Disable skip button if we're up to skip limit
      //Dev note: Change request to always hide skip on mobile, so mobile skip limit is ignored
      if (
        currentTourState.TourSkipCount >= skipLimit ||
        isDesktopView === false
      ) {
        $skip.addClass("-disabled");
      }
    });

    $slider.slick({
      dots: true,
      infinite: false,
      speed: 500,
      prevArrow: `<button class="slick-prev" type="button">${previousText}</button>`,
      nextArrow: `<button class="slick-next" type="button">${nextText}</button>`,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            speed: 200,
          },
        },
      ],
    });
  }

  function setupEvents() {
    //Skip button, but can end as a finish for mobile on last slide
    $skip.on("click touchend", function () {
      if (!$skip.hasClass("-disabled")) {
        const isSkip = $onboarding.hasClass("-atend") ? false : true;
        endTour(isSkip);
      }
    });

    $next.on("click", function () {
      $slider.slick("slickNext");
    });

    $slider.on("afterChange", handleSlickChange);

    //Quick workaround to block interacting with dots
    //tabindex is set after init event...
    $slider.find(".slick-dots li").off("click");
    setTimeout(() => {
      $slider.find(".slick-dots li button").attr("tabindex", "-1");
    }, 500);
  }

  function handleNextEnd() {
    endTour(false);
  }

  function handleSlickChange(ev, slick, currentSlide) {
    $currentPageCurrent.text(currentSlide + 1);

    //tabindex is set after change event...
    setTimeout(() => {
      $slider.find(".slick-dots li button").attr("tabindex", "-1");
    }, 500);

    if (currentSlide + 1 === totalSlides) {
      $onboarding.addClass("-atend");
      $slider.find(".slick-next").html("Finish");

      $(document).on("click", ".onboarding .slick-next", handleNextEnd);
    } else {
      $slider.find(".slick-next").html(nextText);
      $onboarding.removeClass("-atend");

      $(document).off("click", ".onboarding .slick-next", handleNextEnd);
    }

    if (currentSlide === 0) {
      $onboarding.addClass("-atstart");
    } else {
      $onboarding.removeClass("-atstart");
    }
  }

  function endTour(isSkip) {
    if (isSkip === true) {
      currentTourState.TourSkipCount = currentTourState.TourSkipCount + 1;
    } else {
      currentTourState.TourCompleted = true;
    }

    focusTrap.deactivate();
    $("html").removeClass("hide-scroll");
    $onboarding.fadeOut();
    $skip.blur();

    //console.log('Tour state to be saved...');
    //console.log(currentTourState);

    //Update desktop or mobile tour
    let propKey = isDesktopView ? "TourState" : "MobileTourState";
    userStateManager.setSubState(currentTourState, propKey);
    //Update session status
    sessionStorage.setItem("tourRanThisSession", "true");
  }
};
